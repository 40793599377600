import React from "react";
import Cookies from 'js-cookie';
import {Buffer} from 'buffer';
import 'bootstrap/dist/css/bootstrap.min.css';

import FormInputPhoneNumber from '../../../_components/_templates/formInputPhoneNumber';
import FormInputOTP from '../../../_components/_templates/formInputOTP';
import ShowRegisterForm from '../../../_components/_templates/showRegisterForm';
import ShowRegisterFormDisableOTP from '../../../_components/_templates/showRegisterFormDisableOTP';
import FrameListener from '../../../_components/_templates/_frameListener';

const regsess = Cookies.get('regsess');
var otpState = 'init';
var phoneNumber:string;
if(regsess){
  const cookieData = JSON.parse(Buffer.from(regsess, 'base64').toString('utf-8'));
  otpState = cookieData.state;
  phoneNumber = cookieData.phoneNumber;
}

class InputPhoneNumber extends React.Component {
    constructor(props:any) {
        super(props);
    }
    
    
    render() {
      if(process.env.REACT_APP_OTP_SMS_DISABLED=='true'){
        return (
          <React.Fragment>
              <ShowRegisterFormDisableOTP />
              <FrameListener />
          </React.Fragment>
        );
      }else{
        return (
          <React.Fragment>
            <div>
            { otpState=='init' ? <FormInputPhoneNumber /> : null }
            { otpState=='requested' ? <FormInputOTP phoneNumber={phoneNumber} /> : null }
            { otpState=='validated' ? <ShowRegisterForm phoneNumber={phoneNumber}/> : null }
            </div>
            <FrameListener />
          </React.Fragment>
        );
      }
    }
}
  
export default InputPhoneNumber;
// export default InputPhoneNumber
  