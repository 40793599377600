import { persistReducer } from "redux-persist";
import { actionTypes } from "./action";

import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
  name: 'formOTPSlice',
  initialState: {
    formState: 'init',
  },
  reducers: {
    changeFormState: (state, actions) => {
      state.formState = actions.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeFormState } = dataSlice.actions

export default dataSlice.reducer