import React, { useState, useEffect } from "react";
import { ThemeProvider, Form, Button, Container, Row, Col } from 'react-bootstrap';
import ButtonRegister from "../buttonRegister";
import OTPService from '../../_services/otp-service';
import Cookie from 'js-cookie';



const FormInputPhoneNumber = ({
  ...other
}:any) => {


  const [invalidResponseMessage, setInvalidResponseMessage] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [otpTitle, setOtpTitle] = useState('');
  const [otpPosition, setOtpPosition] = useState('');
  const [retryTimeLeft, setRetryTimeLeft]:any = useState(null);
  const [retryTimeLeftString, setRetryTimeLeftString]:any = useState(null);

  
  useEffect(() => {
    
    const otpForm = window.name;
    if(otpForm){
      setOtpTitle(otpForm);
    }
  },[])
  
  useEffect(() => {
    if(retryTimeLeft===0){
      setRetryTimeLeft(null)
    }

    if(retryTimeLeft===null){
      setInvalidResponseMessage('')
    }

    // exit early when we reach 0
    if (!retryTimeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setRetryTimeLeft(retryTimeLeft - 1);
    }, 1000);

    let minutes = Math.floor(retryTimeLeft / 60);
    var seconds = retryTimeLeft - (minutes * 60);

    if(minutes>0 || seconds>0){
      if(minutes>0){
        setRetryTimeLeftString(minutes+' menit '+seconds+' detik');
      }else{
        setRetryTimeLeftString(seconds+' detik');
      }
    }else{
      setRetryTimeLeftString(null);
    }

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [retryTimeLeft]);

  const btnStyle: React.CSSProperties = {
    padding: '10px',
    background: '#fcd2ae',
    width: '100%',
    margin: '0 auto',
    color: '#163a50',
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: '0.5rem',
    borderColor: 'transparent',
    borderRadius: '3px'
  };

  const btnStyleHover: React.CSSProperties = {
    background: '#E47425'
  };

  const labelStyle: React.CSSProperties = {
      color: '#163a50',
      fontWeight: '700',
      marginBottom: '0.5rem'
  };

  const inputStyle: React.CSSProperties = {
    borderColor: '#8babc7',
    borderRadius: '0.25rem'
  }

  async function sendOTP(phoneNumber:string){
    const send = await OTPService.sendOTP(phoneNumber);
    if(send.statusCode==401){
      if(process.env.REACT_APP_ENV=='development'){
        document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=127.0.0.1;`;
      }else{
        document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=.jubelio.com;`;
      }
      window.location.reload();
    }else if(send.statusCode==200 && send.message=="ok"){
      window.location.reload()
    }else if(send.statusCode==200 && send.message=="error"){
      switch(send.data.code){
        case 'MISSING_PHONE_NUMBER':
          setInvalidResponseMessage('Nomor telepon harus diisi.');
          break;
        case 'INVALID_PHONE_NUMBER':
          setInvalidResponseMessage('Nomor telepon tidak valid.');
          break;
        case 'PHONE_NUMBER_ALREADY_REGISTERED':
          setInvalidResponseMessage('Nomor telepon sudah pernah dipakai untuk registrasi di Jubelio.');
          break;
        case 'WAIT':
          setRetryTimeLeft(send.data.remainingSeconds);
          break;
        case 'BANNED':
          // setInvalidResponseMessage('You have entered too many OTP request.');
          window.location.reload();
          break;
        default:
          if(send.data.error!=''){
            setInvalidResponseMessage(send.data.error);
          }else{
            setInvalidResponseMessage('Unknown error');
          }
          break;
      }
    }else{
      if(send.error!=undefined){
        if(send.message=='Rate limit exceeded, retry in 1 minute'){
          setInvalidResponseMessage('Too many request, retry in 1 minute');
        }else{
          setInvalidResponseMessage('Unknown error');
        }
      }else{
        setInvalidResponseMessage('Unknown error');
      }
    }
  }

  function phoneNumberHandleChange(e:any) {
    setInvalidResponseMessage('');
    const regex = /^\+?\d*$/;
      if (e.target.value === '' || regex.test(e.target.value)) {
        setphoneNumber(e.target.value);
      }
  };

  function phoneNumberHandleFocus(e:any) {
    setInvalidResponseMessage('');
  };

  const phoneNumberKeyPressed = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      sendOTP(phoneNumber)
    }
  }
  

  return (
    <ThemeProvider
          breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
    >
      <React.Fragment>
        <Container style={{marginLeft:'5px',paddingLeft:'0px'}}>
          <Row className="justify-content-md-left">
            <Col xs lg="3">
            <h5 style={{marginTop:otpPosition,fontSize:'0.9rem',fontWeight:'500'}}>{otpTitle}</h5>
              <Form.Group className="mb-3" controlId="otp_phoneNumber">
              
                  <Form.Control style={invalidResponseMessage!=''?{borderColor:"red"}:inputStyle} type="text" value={phoneNumber} 
                    placeholder="Nomor Handphone"
                    onChange={phoneNumberHandleChange} 
                    onFocus={phoneNumberHandleFocus}
                    onKeyDown={(e) => phoneNumberKeyPressed(e)}/>
                  <p id="otp_sendError" style={invalidResponseMessage==''?{display:"none"}:{}}>
                    <small id="otp_sendErrorMsg" style={true?{color:"red", fontSize: "12px"}:{}}>
                      {invalidResponseMessage}
                    </small>
                  </p>
                  <p id="otp_sendErrorRetry" style={retryTimeLeft<=0?{display:"none"}:{}}>
                    <small id="otp_sendErrorRetryMsg" style={true?{color:"red", fontSize: "12px"}:{}}>
                     Anda baru saja melakukan pengiriman kode OTP, mohon tunggu {retryTimeLeftString} untuk kirim ulang
                    </small>
                  </p>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-md-left">
            <Col xs="5" lg="5">
              <ButtonRegister 
                onClick={() => sendOTP(phoneNumber)} 
                text= 'Kirim OTP'>
              </ButtonRegister>
            </Col>
          </Row>
        </Container>
      </React.Fragment>    
    </ThemeProvider>
  );
};

export default FormInputPhoneNumber;