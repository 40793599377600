import axios from 'axios';
import Cookies from 'js-cookie';

const invalidateCSRF = async function (){
  if(process.env.REACT_APP_ENV=='development'){
    document.cookie = `_otp__csrf=; Max-Age=-99999999;path=/;domain=127.0.0.1;`;
    document.cookie = `_otp__csrf_token=; Max-Age=-99999999;path=/;domain=127.0.0.1;`;
  }else{
    document.cookie = `_otp__csrf=; Max-Age=-99999999;path=/;domain=.jubelio.com;`;
    document.cookie = `_otp__csrf_token=; Max-Age=-99999999;path=/;domain=.jubelio.com;`;
  }
}

const setCSRF = async function (){
  try {
      const fetch = await axios.get(process.env.REACT_APP_BACKEND_SMS_OTP_BASEPOINT+'/csrf',{ withCredentials: true });
      return fetch.data;
    } catch (error:any) {
      if(error.response.data!=undefined){
        return error.response.data;
      }else{
        return {
          statusCode: 400,
          message: "error"
        };
      }
      
    }
}

const checkResendOTP = async function (){
  await setCSRF();
  try {
      const fetch = await axios.post(process.env.REACT_APP_BACKEND_SMS_OTP_BASEPOINT+'/otp-sms/check-resend',{},{ 
        withCredentials: true,
        headers: {
          'x-otp-csrf-secret': Cookies.get('_otp__csrf') || '',
          'x-otp-csrf-token': Cookies.get('_otp__csrf_token') || ''
        }
      });
      return fetch.data;
    } catch (error:any) {
      if(error.response.data!=undefined){
        return error.response.data;
      }else{
        return {
          statusCode: 400,
          message: "error"
        };
      }
      
    }
}

const sendOTP = async function (phoneNumber?:string){
    await setCSRF();
    try {
        const fetch = await axios.post(process.env.REACT_APP_BACKEND_SMS_OTP_BASEPOINT+'/otp-sms/send',{phoneNumber: phoneNumber},{ 
          withCredentials: true,
          headers: {
            'x-otp-csrf-secret': Cookies.get('_otp__csrf') || '',
            'x-otp-csrf-token': Cookies.get('_otp__csrf_token') || ''
          }
        });
        invalidateCSRF();
        return fetch.data;
      } catch (error:any) {
        if(error.response.data!=undefined){
          invalidateCSRF();
          return error.response.data;
        }else{
          invalidateCSRF();
          return {
            statusCode: 400,
            message: "error"
          };
        }
        
      }
}

const validateOTP = async function (otp:number){
  await setCSRF();
  try {
      const fetch = await axios.post(process.env.REACT_APP_BACKEND_SMS_OTP_BASEPOINT+'/otp-sms/validate',{otp: otp},{ 
        withCredentials: true,
        headers: {
          'x-otp-csrf-secret': Cookies.get('_otp__csrf') || '',
          'x-otp-csrf-token': Cookies.get('_otp__csrf_token') || ''
        }
      });
      return fetch.data;
    } catch (error:any) {
      if(error.response.data!=undefined){
        return error.response.data;
      }else{
        return {
          statusCode: 400,
          message: "error"
        };
      }
    }
}

export default {
    sendOTP: sendOTP,
    checkResendOTP: checkResendOTP,
    validateOTP: validateOTP,
    setCSRF: setCSRF
}
  

