import React, {useEffect} from "react";
import Cookie from 'js-cookie';

const FrameListener = ({
  ...other
}:any) => {


  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('message', event => {
      if (event.origin.includes(process.env.REACT_APP_WINDOW_EVENT_LISTENER_ORIGIN||"http://127.0.0.1")) {
         const eventData= event.data;
         const messageType= eventData.messageType;
         switch(messageType){
            case 'SMS__PHONE_OTP_INVALIDATED':
              if(process.env.REACT_APP_ENV=='development'){
                document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=127.0.0.1;`;
              }else{
                document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=.jubelio.com;`;
              }
              
              Cookie.remove('regsess');
              // eslint-disable-next-line no-restricted-globals
              parent.postMessage({
                messageType:'RELOAD_PAGE'
              },process.env.REACT_APP_WINDOW_EVENT_LISTENER_POST_TARGET_ORIGIN||"");
              return false;
              break;
            default:
               return;
         }
      } else {
         return;
      }
     });
    }, []);

  return (
    <React.Fragment>
    </React.Fragment>
  );

}

export default FrameListener;