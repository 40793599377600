import React, {useEffect} from "react";

const ShowRegisterFormDisableOTP = ({
  ...other
}:any) => {


  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({
                        messageType:'SMS__PHONE_OTP_DISABLED'
                      },process.env.REACT_APP_WINDOW_EVENT_LISTENER_POST_TARGET_ORIGIN||"")
    }, []);

  return (
    <React.Fragment>
    </React.Fragment>
  );

}

export default ShowRegisterFormDisableOTP;