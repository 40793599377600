import React, { useState } from "react";
import { Button} from 'react-bootstrap';

const BtnRegister = ({
  onClick,
  text,
  ...other
}:any) => {

  const [isHovered, setHoverStatus] = useState(false);

  const btnStyle: React.CSSProperties = {
    padding: '10px',
    background: '#fcd2ae',
    width: '100%',
    margin: '0 auto',
    color: '#163a50',
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: '0.5rem',
    borderColor: 'transparent',
    borderRadius: '3px'
  };

  const btnStyleHover: React.CSSProperties = {
    padding: '10px',
    background: '#E47425',
    width: '100%',
    margin: '0 auto',
    color: '#ffffff',
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: '0.5rem',
    borderColor: 'transparent',
    borderRadius: '3px'
  };


  return (
   
      <React.Fragment>
        <Button 
            onMouseEnter={() => setHoverStatus(true)}
            onMouseLeave={() => setHoverStatus(false)}
            onClick={onClick?onClick:null}
            style={!isHovered?btnStyle:btnStyleHover} 
            type="button">
                {text}
            </Button>
      </React.Fragment>  
  );
};

export default BtnRegister;