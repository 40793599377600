import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import BasePage from "./BasePage";
import RoutesSMSOTPSingle from "./modules/sms-otp-single/routes";

export default function App() {
  const callerOrigin = document.referrer;
  var a=document.createElement('a');
  const selfDomain= process.env.REACT_APP_SELF_DOMAIN || ''
  a.href=callerOrigin;

  if((a.hostname=='127.0.0.1' || a.hostname.includes('jubelio.com'))){
    return (
      /* Provide Redux store */
      // <Provider store={store}>
          // {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          // <React.Suspense>
            // {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                {/* <Suspense> */}
            <Routes>
              <Route
                  path="/sms-otp/*"
                  element={<RoutesSMSOTPSingle/>}
              />
            </Routes>
          {/* </Suspense> */}
            </BrowserRouter>
          // </React.Suspense>
      // </Provider>
    );
  }else{
    return (
      <React.Fragment>
        Forbidden.
      </React.Fragment>
      );
  }
  
}