import React, {useState, useRef, useEffect} from "react";
import { ThemeProvider, Form, Button, Container, Row, Col } from 'react-bootstrap';
import OTPInput from 'react-otp-input';
import OTPService from '../../_services/otp-service';
import Cookie from 'js-cookie';



const useFocus = () => {
	const htmlElRef:any = useRef(null)
	const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
	return [ htmlElRef,  setFocus ] 
}

const FormInputOTP = ({
  phoneNumber,
  ...other
}:any) => {
  const retryButtonRefresh = parseInt(process.env.REACT_APP_SEND_OTP_RETRY_INTERVAL||"60");
  const retryBanned = parseInt(process.env.REACT_APP_SEND_OTP_BANNED_VALUE||"3599");
  const maxRetry = parseInt(process.env.REACT_APP_SEND_OTP_MAX_RETRY||"3");
  const otpLength = parseInt(process.env.REACT_APP_OTP_LENGTH||"6");

  const [statusError, setStatusError] = useState('');
  const [invalidResponseMessage, setInvalidResponseMessage] = useState('');
  const [isInitialBanned, setInitialBanned] = useState(false);
  const [otp, setOtp] = useState('');
  const [isBannedInput, setBannedInput] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);
  const [retryStatus, setRetryStatus] = useState(false);
  const [isInitialLoaded, setInitialLoaded] = useState(false);
  const [retryTimeLeft, setRetryTimeLeft]:any = useState(null);
  const [retryTimeLeftString, setRetryTimeLeftString]:any = useState(null);
  const [remainingAttempts, setRemainingAttempts]:any = useState(maxRetry-1);
  const [inputRefFocus, setInputFocus] = useFocus();
  const [width, setWindowWidth] = useState(0);

  
  const updateDimensions = () => {
    const width = window.outerWidth
    setWindowWidth(width)
  }
  
  useEffect(() => { 
    updateDimensions();
    window.addEventListener("resize", updateDimensions);     
      return () => 
        window.removeEventListener("resize",updateDimensions);    
  }, [])


  const otpBoxSize = width<1680 && width>990?'2.3rem':'3rem';


  function handleChange(otp:any){
    setOtp(otp);
    if(otp.length==otpLength){
      setInputFocus();
      validateOTP(otp);
      setSendStatus(true);
    }
  }

  function invalidateSession(){
    if(process.env.REACT_APP_ENV=='development'){
      document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=127.0.0.1;`;
    }else{
      document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=.jubelio.com;`;
    }
    Cookie.remove('regsess');
    window.location.reload();
  }

  async function checkResendOTP(){
    const send = await OTPService.checkResendOTP();
    if(send.statusCode==401){
      invalidateSession();
    }else if(send.statusCode==200 && send.message=="ok"){
      setStatusError('WAIT');
      setRemainingAttempts(maxRetry-send.data.countSent);
      setRetryTimeLeft(null);
      setInitialLoaded(true);
    }else if(send.statusCode==200 && send.message=="error"){
      setStatusError(send.data.code);
      switch(send.data.code){
        case 'INVALID_DATA':
          invalidateSession();
          break;
        case 'WAIT':
          setRemainingAttempts(maxRetry-send.data.countSent);
          setRetryTimeLeft(send.data.remainingSeconds);
          setInitialLoaded(true);
          break;
        case 'BANNED':
          setInitialLoaded(true);
          setRetryTimeLeft(send.data.remainingSeconds);
          break;
        case 'BANNED_INPUT':
          setInitialLoaded(true);
          setBannedInput(true);
          setRetryTimeLeft(send.data.remainingSeconds);
          setInvalidResponseMessage("BANNED_INPUT");
          break;
        default:
          invalidateSession();
          break;
      }
    }else{
      if(process.env.REACT_APP_ENV=='development'){
        document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=127.0.0.1;`;
      }else{
        document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=.jubelio.com;`;
      }
      Cookie.remove('regsess');
      setInvalidResponseMessage("Unknown error.");
    }
  }

  async function changePhoneNumber(){
    invalidateSession();
  }

  async function resendOTP(){
    const send = await OTPService.sendOTP();
    if(send.statusCode==401){
      invalidateSession();
    }else if(send.statusCode==200 && send.message=="ok"){
      setStatusError('WAIT');
      setRemainingAttempts(maxRetry-send.data.countSent);
      if(send.data.countSent>=3){
        setInitialBanned(true);
        setStatusError('BANNED');
        setRetryTimeLeft(retryBanned)
      }else{
        setRetryTimeLeft(retryButtonRefresh)
      }
      
    }else if(send.statusCode==200 && send.message=="error"){
      setStatusError(send.data.code);
      switch(send.data.code){
        case 'MISSING_PHONE_NUMBER':
          invalidateSession();
          break;
        case 'INVALID_PHONE_NUMBER':
          invalidateSession();
          break;
        case 'WAIT':
          setRemainingAttempts(maxRetry-send.data.countSent);
          setRetryTimeLeft(send.data.remainingSeconds);
          break;
        case 'BANNED':
          setRetryTimeLeft(send.data.remainingSeconds);
          window.location.reload();
          break;
        case 'BANNED_INPUT':
          window.location.reload();
          break;
        default:
          window.location.reload();
          break;
      }
    }else{
      if(process.env.REACT_APP_ENV=='development'){
        document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=127.0.0.1;`;
      }else{
        document.cookie = `regsess=; Max-Age=-99999999;path=/;domain=.jubelio.com;`;
      }
      Cookie.remove('regsess');
      setInvalidResponseMessage("Unknown error.");
    }
  }

  async function validateOTP(otp:number){
    const send = await OTPService.validateOTP(otp);
    if(send.statusCode==401){
      invalidateSession();
    }else if(send.statusCode==200 && send.message=="ok"){
      window.location.reload()
    }else if(send.statusCode==200 && send.message=="error"){
      if(send.data.code=="WRONG_OTP"){
        setInvalidResponseMessage("Verifikasi gagal, Kode OTP tidak valid.");
      }else if(send.data.code=="MAX_RETRY"){
        window.location.reload();
      }else{
        setInvalidResponseMessage("Unknown error.");
      }
    }else{
      console.log(send);
    }
  }

  function otpInputHandleFocus(e:any) {
    setInvalidResponseMessage('');
    if(sendStatus==true){
      setSendStatus(false);
      setOtp('');
    }
  };

  useEffect(() => {
    // Run initial countdown
    checkResendOTP();
  }, []);

  useEffect(() => {
    if(retryTimeLeft===0){
      setRetryTimeLeft(null)
    }

    if(retryTimeLeft===null){
      setRetryStatus(true)
    }else{
      setRetryStatus(false)
    }

    // exit early when we reach 0
    if (!retryTimeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setRetryTimeLeft(retryTimeLeft - 1);
    }, 1000);

    let minutes = Math.floor(retryTimeLeft / 60);
    var seconds = retryTimeLeft - (minutes * 60);

    if(minutes>0 || seconds>0){
      if(minutes>0){
        setRetryTimeLeftString(minutes+' menit '+seconds+' detik');
      }else{
        setRetryTimeLeftString(seconds+' detik');
      }
    }else{
      setRetryTimeLeftString(null);
    }

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [retryTimeLeft]);

    return (
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      >
        <React.Fragment>
          <Container>
            <Row className="justify-content-md-center">
              <Col style={true?{textAlign:"center"}:{}} onFocus={otpInputHandleFocus}>
                <OTPInput
                containerStyle={true?{display:"inline-flex"}:{}}
                inputStyle={invalidResponseMessage!='' && !isBannedInput?
                {
                  width: otpBoxSize,
                  height: otpBoxSize,
                  margin: "0.3rem",
                  fontSize: "2rem",
                  border: "1px solid red"
                }:invalidResponseMessage!='' && isBannedInput?
                {
                  width: otpBoxSize,
                  height: otpBoxSize,
                  margin: "0.3rem",
                  fontSize: "2rem",
                  border: "1px solid red",
                  backgroundColor: "#ddd9d9"
                }:
                {
                  width: otpBoxSize,
                  height: otpBoxSize,
                  margin: "0.3rem",
                  fontSize: "2rem"
                }}
                shouldAutoFocus={isBannedInput?false:true}
                value={otp}
                onChange={handleChange}
                // onFocus={handleChange}
                numInputs={otpLength}
                isInputNum={true}
                isInputSecure={false}
                separator={<span>&nbsp;</span>}
                isDisabled={isBannedInput}
                />
              </Col>
            </Row>
            {isInitialLoaded?(
            <Row>
              <Col>
                <p id="otp_sendError" style={invalidResponseMessage!=''?{display:"block",textAlign:"center"}:{}} > 
                  <small id="otp_sendErrorMsg" style={true?{color:"red", fontSize: "12px"}:{}}>
                    {invalidResponseMessage=="BANNED_INPUT"?
                    "Anda terlalu banyak memasukan kode OTP yang salah. Anda bisa mencoba melakukan verifikasi kembali dalam waktu "+retryTimeLeftString
                    :
                    invalidResponseMessage
                    }
                  </small>
                </p>
                {
                  statusError=='WAIT'?
                  (
                    <Container>
                      <p id="otp_resendWait" className="text-center otp-text" style={retryStatus==true?{display:"none"}:{color:"#3b6284"}}>Kode OTP telah berhasil dikirim, mohon tunggu <b>{retryTimeLeftString}</b> untuk kirim ulang</p>
                      <p id="otp_resend" className="text-center otp-text" style={retryStatus==true?{display:"block",color:"#3b6284"}:{display:"none"}}>Tidak menerima kode OTP? <a ref={inputRefFocus} href="#" onClick={() => resendOTP()} style={{color:'#4c68f0', fontWeight:700}}>Kirim Ulang</a><br/><small>Sisa kesempatan untuk kirim ulang: {remainingAttempts}</small></p>
                    </Container>
                  ):statusError=='BANNED'?
                  (
                    <Container>
                      <p id="otp_penalty" style={{color:"#3b6284"}} className="text-center otp-text">
                        { isInitialBanned?
                          `Kode OTP telah berhasil dikirim. Anda telah menggunakan kesempatan terakhir untuk kirim ulang kode OTP. Anda bisa mengirimkan kode OTP lagi setelah `
                          :
                          `Anda telah mencapai batas maksimum kirim ulang kode OTP. Anda bisa mengirimkan kode OTP lagi setelah `
                        }
                        <b>{retryTimeLeftString}</b>
                      </p>
                    </Container>
                  )
                  :null
                }
                <Container>
                  <p className="text-center otp-text">
                    <small>
                      <a ref={inputRefFocus} href="#" onClick={() => changePhoneNumber() } style={{color:'#3b6284', fontWeight:700}}>
                        Ganti Nomor Telepon
                      </a>
                    </small>
                  </p>
                </Container>
              </Col>
            </Row>)
            :null }
          </Container>
        </React.Fragment>
        
      </ThemeProvider>
    );
  
}

export default FormInputOTP;