import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { store } from './_state/store';
import {
    // FormattedMessage,
    injectIntl,
  } from "react-intl";
import { connect, Provider } from "react-redux";

import  { InputPhoneNumber } from "./pages";

export default function RootOTP(_props:any) {
    return (
      <Provider store={store}>
        <Routes>
          <Route
            path="/"
            element={<InputPhoneNumber/>}
          />
        </Routes>
      </Provider>
    );
  }
//   export default injectIntl((RootOTP));