import React, {useEffect} from "react";

const ShowRegisterForm = ({
  phoneNumber,
  ...other
}:any) => {


  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({
                        messageType:'SMS__PHONE_OTP_VALIDATED',
                        data:{
                          phoneNumber:phoneNumber
                        }
                      },process.env.REACT_APP_WINDOW_EVENT_LISTENER_POST_TARGET_ORIGIN||"")
    }, []);

  return (
    <React.Fragment>
    </React.Fragment>
  );

}

export default ShowRegisterForm;